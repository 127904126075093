import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import { CategoryPage, ICategoryProps } from 'components/Category/CategoryPage';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';

const NetworkHomePage = (props: ICategoryProps) => {
  const title = `${props?.category?.categoryName} News | On3.com`;
  const description = `The latest ${props?.category?.categoryName} News.`;

  return (
    <PageWrapper {...props} description={description} title={title}>
      <CategoryPage {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  const { currentSite } = siteData || {};
  const currentCategory = currentSite?.siteCategories?.find(
    (siteCategory) => siteCategory?.categoryType === 'Home',
  );

  if (!currentCategory?.categoryKey) {
    console.error({ url: ctx?.req?.url, error: 'category null' });

    return {
      notFound: true,
    };
  }

  try {
    const { page = 1 } = ctx.query;
    const params = {
      page,
    };
    const [articles] = await Promise.allSettled([
      proxyApi
        .get(
          `/content/v1/categories/${currentCategory?.categoryKey}/headlines-paginated/`,
          { params },
        )
        .then((r) => r.data),
    ]);

    return {
      props: {
        pageType: 'category',
        siteData: siteData || [],
        userData: userData || {},
        category: currentCategory,
        articles: articles.status === 'fulfilled' ? articles.value : {},
        page:
          articles.status === 'fulfilled'
            ? articles.value.pagination.currentPage
            : {},
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err.message);

    return { props: {} };
  }
};

export default withValidUrl(NetworkHomePage);
